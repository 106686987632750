import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import LocalizedLink from '../i18n/localizedLink'
import Img from 'gatsby-image'
import styles from './news.module.scss'

const NewsPage = ({
  pageContext: { limit, skip, numPages, currentPage, locale, hrefLangs },
  data,
}) => {
  const hasPre = currentPage > 1 && numPages > 1
  const hasNext = currentPage < numPages && numPages > 1
  return (
    <Layout locale={locale}>
      <SEO
        title="News"
        description="List of the Loquat latest news."
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
          <section className={styles.hero}>
            <h1 className={styles.hero_heading}>
              <FormattedMessage id="news" />
            </h1>
            <figure className={styles.hero_img}>
            <Img fluid={data.news.childImageSharp.fluid} />
            </figure>
          </section>
        <div className="wrapper">
          <section className={styles.news}>
            <h2>
              <FormattedMessage id="news" />
            </h2>
            {data.allContentfulNews.edges.map(({ node }) => (
              <article key={node.newsId} className={styles.article}>
                <time>{node.publishedAt}</time>
                <LocalizedLink to={'/news/' + node.newsId + '/'}>
                  <h3>{node.title}</h3>
                </LocalizedLink>
              </article>
            ))}
          </section>
          <nav className={styles.pager}>
            <ul className={styles.pagination}>
              {hasPre && (
                <li className={styles.pre}>
                  <LocalizedLink
                    to={
                      currentPage === 2
                        ? '/news/'
                        : '/news/' + (currentPage - 2) + '/'
                    }
                  >
                    <span>&#60;</span>
                  </LocalizedLink>
                </li>
              )}
              {Array.from({ length: numPages }).map((_, i) => {
                var link = i === 0 ? '' : `${i}/`
                return (
                  <li key={i}>
                    <LocalizedLink
                      to={'/news/' + link}
                      activeClassName={styles.active}
                    >
                      <span>{i + 1}</span>
                    </LocalizedLink>
                  </li>
                )
              })}
              {hasNext && (
                <li className={styles.next}>
                  <LocalizedLink to={'/news/' + currentPage + '/'}>
                    <span>&#62;</span>
                  </LocalizedLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default NewsPage

export const query = graphql`
  query($skip: Int!, $limit: Int!, $localeLanguage: String!) {
    allContentfulNews(
      sort: { fields: [publishedAt], order: DESC }
      filter: { node_locale: { eq: $localeLanguage } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          newsId
          title
          publishedAt
        }
      }
    }
    news: file(relativePath: { eq: "head.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
